@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Ubuntu:wght@300;400;500;700&display=swap);
html {
    scroll-behavior: smooth;
}

/*Scroll bar Styling*/
::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: Abhaya;
    src: url(/static/media/UN-Abhaya.5a5d00e7.ttf);
}

@font-face {
    font-family: Alakamanda;
    src: url(/static/media/UN-Alakamanda.eb2ca4e6.ttf);
}

@font-face {
    font-family: Agni;
    src: url(/static/media/UN-Agni.1651c3da.ttf);
}

@font-face {
    font-family: Gurulugomi;
    src: url(/static/media/UN-Gurulugomi.6b0ac599.ttf);
}

@font-face {
    font-family: Davasa;
    src: url(/static/media/UN-Davasa.9be44ac6.ttf);
}

@font-face {
    font-family: Indeewaree;
    src: url(/static/media/UN-Indeewaree.4a1b276b.ttf);
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Spinner_spinner__3k70y {
  position: absolute;
  width: 400px;
  height: 400px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Spinner_doubleBounce1__10ccP,
.Spinner_doubleBounce2__1qZIk {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #2b366c;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: Spinner_sk-bounce__338HK 2s infinite ease-in-out;
  animation: Spinner_sk-bounce__338HK 2s infinite ease-in-out;
}

.Spinner_doubleBounce2__1qZIk {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

/*Animations*/
@-webkit-keyframes Spinner_sk-bounce__338HK {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes Spinner_sk-bounce__338HK {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.Questions_questionStyle__3Lr4V {
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 10px;
    padding: 10px;
    width: 88%;
    top: 50%;
    left: 54%;
}

@media all and (max-width: 542px) {
    .Questions_questionStyle__3Lr4V {
        grid-template-columns: repeat(5, 1fr);
        width: 40%;
        top: 92%;
    }
}
