.questionStyle {
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 10px;
    padding: 10px;
    width: 88%;
    top: 50%;
    left: 54%;
}

@media all and (max-width: 542px) {
    .questionStyle {
        grid-template-columns: repeat(5, 1fr);
        width: 40%;
        top: 92%;
    }
}