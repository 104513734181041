@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Ubuntu:wght@300;400;500;700&display=swap");

html {
    scroll-behavior: smooth;
}

/*Scroll bar Styling*/
::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: Abhaya;
    src: url(assets/fonts/UN-Abhaya.ttf);
}

@font-face {
    font-family: Alakamanda;
    src: url(assets/fonts/UN-Alakamanda.ttf);
}

@font-face {
    font-family: Agni;
    src: url(assets/fonts/UN-Agni.ttf);
}

@font-face {
    font-family: Gurulugomi;
    src: url(assets/fonts/UN-Gurulugomi.ttf);
}

@font-face {
    font-family: Davasa;
    src: url(assets/fonts/UN-Davasa.ttf);
}

@font-face {
    font-family: Indeewaree;
    src: url(assets/fonts/UN-Indeewaree.ttf);
}
